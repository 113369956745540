import React, { useState, useCallback } from 'react'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import PageHeader from '../components/ui/page-header'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

const Factory = () => {
  const photos = [
    {
      src: '/images/factory-23.jpg',
      width: 300,
      height: 200,
    },
    {
      src: '/images/factory-31.jpg',
      width: 300,
      height: 200,
    },
    {
      src: '/images/factory-41.jpg',
      width: 300,
      height: 200,
    },
    {
      src: '/images/factory-51.jpg',
      width: 300,
      height: 200,
    },
    {
      src: '/images/factory-61.jpg',
      width: 300,
      height: 200,
    },
    {
      src: '/images/factory-71.jpg',
      width: 300,
      height: 200,
    },
  ]

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout menuLang='en'>
      <Seo
        title='Factory'
        description='Sayin Textile Factory'
        article
        lang='en'
      />
      <PageHeader title='Factory' />
      <Container className='py-10 px-5'>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox} className='w-1/2'>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Container>
    </Layout>
  )
}

export default Factory
